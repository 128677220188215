import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import Spinner from "../app/shared/Spinner";

const Error404 = lazy(() => import("./error-pages/Error404"));
const Chipinfo = lazy(() => import("./chipdata"));

function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          path="/:username/:shortkey/:profileType/:redirectkey"
          children={<Chipinfo />}
        />
        <Route path="/404" component={Error404} />
        <Redirect
          to={{
            pathname: "/404",
          }}
        />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;
