import { API_ROOT } from "../../api/config";
import cogoToast from "cogo-toast";

/* todo: Refactor this after URL constants */
function sanitizeUrl(url) {
  const urlParts = url.split("?");

  // if (!urlParts[0].endsWith("/")) {
  //   urlParts[0] += "/";
  // }
  return urlParts.join("?");
}

export function parseError(error: string): string {
  return error || "Something went wrong";
}

export default function request(
  url: string,
  options: Object = {},
  skipAuthToken
): Promise<*> {
  const config = {
    method: "GET",
    ...options,
  };
  const errors = [];

  if (!url) {
    errors.push("url");
  }

  if (
    !config.payload &&
    config.method !== "GET" &&
    config.method !== "DELETE"
  ) {
    errors.push("payload");
  }

  if (errors.length) {
    throw new Error(`Error! You must pass \`${errors.join("`, `")}\``);
  }
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    ...(!skipAuthToken && {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
    ...config.headers,
  };

  const params: Object = {
    headers,
    method: config.method,
  };

  if (params.method !== "GET") {
    params.body = JSON.stringify(config.payload);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks

  return fetch(`${API_ROOT}${sanitizeUrl(url)}`, params).then(
    async (response) => {
      if (response.status > 299) {
        const contentType = response.headers.get("content-type");
        switch (response.status) {
          case 401:
          case 403:
            cogoToast.warn("Authentication Error!", {
              position: "top-right",
              heading: "Error!",
            });
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/#/login";
            }, 4000);
            break;
          case 405:
            cogoToast.warn("Operation is not permitted");
            break;
          case 422:
            cogoToast.warn(
              response.message ? response.message : "Cannot perform action"
            );
            break;
          case 503:
            cogoToast.warn("Oops... Something went wrong.");
            break;
          default:
            break;
        }
        if (response.status === 404) {
          return { status: response.status };
        }

        if (response.status === 400) {
          const responseJson = await response.json();
          return {
            response: responseJson,
            status: response.status,
            error: true,
          };
        }
        const error = { status: response.statusText };

        if (contentType && contentType.includes("application/json")) {
          error.response = {
            status: response.status,
            data: await response.json(),
          };
        } else {
          error.response = {
            status: response.status,
            data: await response.text(),
          };
        }

        throw error;
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          if (config.toast) {
            cogoToast(config.toast);
          }
          return response.json();
        }

        return response.text();
      }
    }
  );
}
