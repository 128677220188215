import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { AppProvider, AppConsumer } from "./app/app-context";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <HashRouter>
    <div>
      <AppProvider>
        <AppConsumer>{() => <App />}</AppConsumer>
      </AppProvider>
    </div>
  </HashRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
