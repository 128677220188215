import React from "react";
import { withRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./reset.css";
import "../assets/css/font-awesome.css";
import "../assets/css/styles.css"

function App({ location }) {
  return (
    <div className="container-scroller">
      <div className="container-fluid full-page-wrapper p-0">
        <div className="main-panel p-0">
          <div className="content-wrapper p-0">
            <AppRoutes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
